
<template>
    <div>
        <template v-if="row.DocumentName">
            <FormButton size="small" type="text" onclick="window.open(this.href,'_blank');return false;" :href="row.DocumentName" v-if="row.DocumentName" tag="a" left-icon="md-eye">View</FormButton>
        </template>
    </div>
</template>

<script>
    export default {
        name: "DocumentView",
        props: {
            row: {
                type: Object,
                required: true
            }
        },
        methods:{
            link() {
            }
        }
    }
</script>
