import DoucmentView from "../../components/DoucmentView";
export default [
  {
    type: "selection",
    width: 38,
    align: "center",
  },
  {
    title: "Name",
    key: "StaffName",
    minWidth:80,
  },
  {
    title: "Manager Name",
    key: "ManagerName",
    minWidth:80,
  },
  {
    title: "Resignation Date",
    key: "ResignationDate",
    minWidth:80,
  },
  {
    title: "Last Working Date",
    key: "LastWorkingDate",
    minWidth:80,
  },
];
